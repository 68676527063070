/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  Container,
  Content,
  Input,
  PageHeading,
  Sidebar,
  Topbar,
  Wrapper,
} from "../../components";
import axios from "axios";
import { BtoA } from "../../helpers";

function SettingTransferBank() {
  const [form, setForm] = useState({
    pesan: "",
    maxwithdraw: 0,
    bankallowed: "",
    maxwithdrawPerMonth: 0,
    maxtfBank: 0,
    maxtfBankPerMonth: 0,
  });

  //   const [asli, setAsli] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");

  useEffect(() => {
    if (!selectedClient) return;
    getData();
  }, [selectedClient]);

  useEffect(() => {
    getCLient();
  }, []);

  const getCLient = async (file) => {
    const res = await axios.get(
      "https://apps-build.berkah-ts.my.id/api/clients"
    );
    const data = res?.data;
    if (!data.success) {
      return toast.error(data?.message);
    } else {
      let client = data?.data;
      //add btsglobal to client
      client = [
        ...client,
        {
          name: "BTSGLOBAL",
          username: "btsglobal",
        },
      ];
      setClients(client);
    }
  };

  const getData = async () => {
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const payload = {
      desc: "systemwithdraw",
      value: JSON.stringify({ clientid: selectedClient }),
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      payload
    );
    const datas = resultUrl.data.lobj[0];
    // setAsli(datas);
    setForm({
      pesan: datas?.pesan,
      maxwithdraw: datas?.maxwithdraw,
      bankallowed: datas?.bankallowed?.split("#").join(","),
      maxwithdrawPerMonth: datas?.maxwithdrawPerMonth,
      maxtfBank: datas?.maxtfBank,
      maxtfBankPerMonth: datas?.maxtfBankPerMonth,
    });
  };

  const saveAction = async (pload) => {
    if (!selectedClient) return toast.error("Pilih Klien");
    const userSave = localStorage.getItem("credential");
    const btoa = BtoA("kocak123");
    const username = btoa(userSave);
    const password = "password";
    const result = await axios.post(
      "https://api.setting.berkah-ts.my.id/oauth/token",
      new URLSearchParams({
        username: username,
        password: password,
        grant_type: "password",
      }),
      {
        headers: {
          Authorization:
            "Basic anViZXItb2F1dGgtc2V0dGluZzpLSWxjPkYrPl09QWl2dzgvMWhxJXtwKVh1RzZpeSNKSVU3XTNpXWNkcVRxby5kT1YlW0osdlZjPSY2MjkwNg==",
        },
      }
    );

    console.log(result.data);

    const token = result.data.access_token;

    const bankallowed = form?.bankallowed?.split(",").join("#");

    const payload = {
      pesan: form.pesan,
      maxwithdraw: form.maxwithdraw,
      bankallowed: bankallowed,
      maxwithdrawPerMonth: form.maxwithdrawPerMonth,
      maxtfBank: form.maxtfBank,
      maxtfBankPerMonth: form.maxtfBankPerMonth,
    };

    const body = {
      desc: "systemwithdraw/update",
      value: JSON.stringify({ clientid: selectedClient, value: payload }),
    };

    const resultUrl = await axios.post(
      `https://api.setting.berkah-ts.my.id/juber/auth/tunnel?access_token=${token}`,
      body
    );

    if (resultUrl.data.code === '200') {
      toast.success("Berhasil");
      getData();
      return;
    } else {
      return toast.error("Gagal");
    }
  };

  return (
    <>
      <Wrapper>
        <Topbar />
        <Container>
          <Sidebar active="setting-transfer-bank" />
          <Content>
            <PageHeading title="Setting Transfer Bank" />
            <div className="row">
              <div className="col-12">
                <Card>
                  <div className="form-group">
                    <label>Klien</label>
                    <select
                      className="form-control"
                      name="type"
                      onChange={(e) => {
                        setSelectedClient(e.target.value);
                      }}
                      value={selectedClient}
                    >
                      <option value="">Pilih Client</option>
                      {clients?.map((item, index) => {
                        return (
                          <option key={index} value={item?.username}>
                            {item?.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <Input
                    label="Max Transfer Bank"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        maxtfBank: e.target.value,
                      });
                    }}
                    value={form?.maxtfBank}
                  />
                  <Input
                    label="Max Transfer Bank Per Bulan"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        maxtfBankPerMonth: e.target.value,
                      });
                    }}
                    value={form?.maxtfBankPerMonth}
                  />
                  <div className="d-flex justify-content-center align-items-end">
                    <Button
                      title={"Simpan"}
                      type="warning"
                      onClick={() => saveAction()}
                    />
                  </div>
                </Card>
              </div>
            </div>
          </Content>
        </Container>
      </Wrapper>
      <ToastContainer />
    </>
  );
}

export default withRouter(SettingTransferBank);
